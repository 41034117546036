
export default {
  name: 'MainPage',
  layout(context) {
    const hasPortfolioPageDisabled =
      context.store.state?.base?.meta?.generalConfig?.portfolioPageDisabled || false
    const layoutConfig = context.store.state.base.meta.landingConfig
    return hasPortfolioPageDisabled
      ? 'project'
      : layoutConfig
      ? layoutConfig.meta.layout
        ? layoutConfig.meta.layout
        : 'default'
      : 'default'
  },
  computed: {
    portfolioPageEnabled() {
      return !this.$store.state.base.meta.generalConfig.portfolioPageDisabled
    }
  }
}
